@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}
body {
  font-family: 'Inter', sans-serif;
  font-weight: 300;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
#root, body{
  height: 100%;
}
img{
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;
  border: none;
  appearance: none;
  outline: 0;
}
video{
  border: none;
  appearance: none;
  outline: 0;
}
a,a:hover,a:focus{
  text-decoration: none;
}
