:root{
  --color-principal: 22,48,41;
  --color-accent: 202,146,51;
  --box-shadow: 0.5rem 0.5rem 1rem -0.7rem rgba(0,0,0,0.5);
}
.box_shadow{
  /*box-shadow: 0 0 10px -5px rgba(0,0,0,0.7);*/
}
.main_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  background: #000;
}
.vista_container{
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: -100%;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	padding: 2rem 2rem 3rem;
	background: rgb(var(--color-accent));
	transition: all 0.5s ease-in-out;
}
.vista_container.active{
	top: 0;
}
.vista_container .close{
	font-size: 0.8em;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	background: rgb(var(--color-accent));
	padding: 1em 1.5em 0.5em;
	text-transform: uppercase;
	font-weight: 500;
	cursor: pointer;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: all 0.2s ease-in-out;
  transition-delay: 0.5s;
}
.vista_container .close:after{
	content: '›';
  display: inline-block;
  transform: rotate(90deg);
  font-size: 1.2em;
  margin-left: 0.5em;
}
.vista_container .close.active:after{
  transform: rotate(-90deg);
}
.vista_container .close.active{
	transform: translateY(0);
  background: rgb(var(--color-principal));
  color: #fff;
  font-weight: 300;
  padding-bottom: 1em;
	padding: 0.5em 1.5em 1em;
  border-radius: 0.5rem 0.5rem 0 0;
}
.vista_container_vistas{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
}
.vista_container_element{
  display: flex;
  color: #313131;
  background: #fff;
  width: fit-content;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
}
.vista_container_element_vista{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  margin-right: 2rem;
}
.vista_container_element_vista:before{
  display: block;
  content: 'Pantalla:';
  font-size: 0.6em;
  font-weight: 500;
  text-transform: capitalize;
}
.vista_container_element_info{
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.7em;
  font-weight: 300;
}
.vista_container_element_info a{
  width: fit-content;
  height: fit-content;
  background: rgb(var(--color-principal));
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  color: #fff;
  text-align: center;
  margin-left: 0.5rem;
}
.vista_container_element_info a span{
  display: flex;
  justify-content: center;
}


.media_container{
  display: flex;
  position: relative;
  flex-grow: 1;
  transition:all 0.2s ease-in-out;
  opacity: 0;
  background: #000;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.media_container.visible{
  opacity: 1;
}

.main_container.home .trm_container{
	width: 100%;
	height: 100%;
	flex-direction: column-reverse;
}
.main_container.home .trm_container_logo{
    max-width: 10rem;
    margin: 0 0 2em;
	order: 1;
}
.main_container.home .trm_container_info{
	flex-grow: 0;
	text-align: center;
}
.main_container.home .trm_container_trm{
    margin-bottom: 1em;
}

.trm_container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 15vh;
  padding: 1rem 2rem;
  background: rgb(var(--color-principal));
  color: #fff;
  transition:all 0.2s ease-in-out;
  user-select: none;
  pointer-events: none;
}
.trm_container_logo{
  width: 100%;
  max-width: 5rem;
  margin: 0 2rem;
}
.trm_container_info{
  display: block;
  flex-grow: 1;
  padding: 0 2rem;
  line-height: 1.2;
  font-size: 0.9em;
}
.trm_container_info h1,
.trm_container_info h2{
  font-size: 1em;
  font-weight: inherit;
}
.trm_container_info h1{
  font-size: 1.8em;
}
.trm_container_info h2{
  font-size: 1em;
}
.trm_container_trm{
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: flex-end;
  height: fit-content;
  padding: 0.6em 1em 0.5em;
  margin: 0 2rem;
  font-size: 2em;
  line-height: 1rem;
  background: rgb(var(--color-accent));
  color: rgb(var(--color-principal));
  font-weight: 500;
}
.trm_container_trm .trm .currency{
  font-size: 0.7em;
  margin-left: 0.2em;
}
.trm_container_trm .trm .trm_sub{
  font-size: 0.7em;
}
.trm_container.visible{
  opacity: 1;
}
.media_container_media{
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.media_container_media.active{
  display: flex;
}
.media_container_media .video{
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: #000;
  /*pointer-events: none;*/
  user-select: none;
  
}
.media_container_media .video.active{
  opacity: 1;
}
/*@media (min-aspect-ratio: 16/9) {
  .media_container_media {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .media_container_media {
    width: 177.78vh;
  }
}*/
@media (max-width: 1400px) {


  .main_container{
    flex-direction: row;
  }
  .trm_container{
    width: 25vw;
    height: 100%;
    flex-direction: column-reverse;
    padding: 1rem 0;
    text-align: center;
    font-size: 0.7em;
  }
  .trm_container_trm{
    margin-bottom: 1em;
  }  
  .trm_container_logo{
    order: 1;
    max-width: 7rem;
    margin: 0 0 2em;
  }
  .trm_container_info{
    flex-grow: 0;
    font-size: 1em;
  }
  .trm_container_info h1{
    margin-bottom: 0.5rem;
  }
  
}

@media (max-width: 700px) {
  .main_container{
    flex-direction: column;
  }
  .trm_container{        
    width: 100%;
    height: auto;
    padding: 5rem 0;
    font-size: 1em;
  }
  .trm_container_trm{
    margin-bottom: 1em;
  }
  .trm_container_info{
    font-size: 1em;
  }
  .trm_container_logo{
    max-width: 10rem;
    margin: 0 0 2em;
  }
  .vista_container_element{
    width: 100%;
  }
  
}

/*@media (max-width: 700px) {

  .trm_container{
    flex-direction: column-reverse;
    padding: 5rem 0;
    text-align: center;
  }
  .trm_container_trm{
    margin-bottom: 1em;
  }  
  .trm_container_logo{
    order: 1;
    max-width: 10rem;
    margin: 0 0 2em;
  }
  .trm_container_info h1{
    margin-bottom: 0.5rem;
  }
  
}*/
/*SOLO MOBILE*/